<template>
	<div class="main">
		<v-container class="permissions-container" style="min-width:300px;">
			<v-row
				key= 1
				justify="space-around"
			>
			<!-- MDL ROLES -->
			<div class="groups-table">
				<v-card-actions style='background: #bdbdbd; margin: auto; height: 40px; margin-top: 20px; margin-left:10px; margin-right:10px;'>
					<v-card-text color="black" style = 'font-weight:bold;' text>
						{{lview.mdlRole}}
					</v-card-text>
				</v-card-actions>

				<!-- Dialog to delete MDL group -->
				<v-dialog
					v-model="dialogMdlDelete"
					width="350px"
					persistent
				>
					<v-card >
						<v-card-title>
							<span> {{lview.deleteGroup}} </span>
						</v-card-title>

						<v-card-text>
							<span> {{lview.deleteGroupContent}} </span>
							<br/><br/>
							<strong> {{lview.groupName}}: </strong> <div> {{ editedMdlName }} </div>
						</v-card-text>
						
						<v-progress-linear
							v-if="loading"
							indeterminate
							color="primary"
						></v-progress-linear>
						
						<v-card-actions>
							<v-spacer></v-spacer>
							<v-btn @click="closeMdlDelete" text :disabled="dialogMdlDeleteDisabled">
								{{lview.cancelButton}}
							</v-btn>
							<v-btn @click="deleteMdlItemConfirm" color="red" text :disabled="dialogMdlDeleteDisabled">
								<strong> {{lview.deleteButton}} </strong>
							</v-btn>
						</v-card-actions>
					</v-card>
				</v-dialog>

				<!-- Dialog to duplicate MDL group -->
				<v-dialog
				v-model="dialogMdlDuplicate"
				width="350px"
				persistent
				>
					<v-card >
						<v-card-title>
							<span> {{lview.duplicateGroup}} </span>
						</v-card-title>

						<v-card-text>
							<span> {{lview.duplicateGroupContent}} </span>
							<strong> {{ editedMdlName }}</strong>?
						</v-card-text>
						
						<v-text-field
							v-model="dialogMdlDuplicateGroupName"
							:disabled="dialogMdlDuplicateInputDisabled"
							:label="lview.groupName"
							style="margin-left: 7%; margin-right: 5%;"
							:maxlength="140"
							:rules="dialogMdlDuplicateNameRules"
							counter
							clearable
						></v-text-field>                

						<v-progress-linear
							v-if="loading"
							indeterminate
							color="primary"
						></v-progress-linear>
						
						<v-card-actions>
							<v-spacer></v-spacer>
							<v-btn @click="closeMdlduplicate" text :disabled="dialogMdlDuplicateCancelDisabled">
								{{lview.cancelButton}}
							</v-btn>
							<v-btn @click="confirmMdlduplicate" color="primary" text :disabled="dialogMdlDuplicateSaveDisabled">
								<strong> {{lview.createButton}} </strong>
							</v-btn>
						</v-card-actions>
					</v-card>
				</v-dialog>

				<!-- MDL roles list (a list that looks like a table) -->
				<v-list
					:class="(expandMdl) ? 'permissions-list expanded' : 'permissions-list'"
				>
					<v-list-item
						class="permissions-header-list-item"
					>
						<div class="permissions-wrapper permissions-header">
							<div class="permissions-wrapper-item permissions-wrapper-item-left">
								{{ lview.headerName }}
							</div>
							<div class="permissions-wrapper-item">
								<span v-if="windowWidth > 450">{{ lview.headerUsers }}</span>
								<span v-else>#</span>
							</div>
							<div class="permissions-wrapper-item">
								<span v-if="windowWidth > 450">{{ lview.headerActions }}</span>
							</div>
						</div>
					</v-list-item>

					<template
						v-for="item in mdl_groups"
					>
						<v-list-item
							class="permissions-list-item py-1"
							v-bind:key="item.id"
						>
							<div class="permissions-wrapper">
								<div class="permissions-wrapper-item permissions-wrapper-item-left">
									{{ item.name }}
								</div>
								<div class="permissions-wrapper-item">
									{{ item.users }}
								</div>
								<div class="permissions-wrapper-item">
									<div>
										<!-- Desktop -->
										<template v-if="windowWidth > 450">
											<v-tooltip bottom>
												<template v-slot:activator="{ on, attrs }">
													<v-btn
														v-if="displayEdit"
														class="mr-2"
														color="primary"
														v-bind="attrs"
														v-on="on"
														@click="$router.push('/mdl-config/' + item.id)"
														icon
														text
														small
													>
														<v-icon>
															mdi-pencil
														</v-icon>
													</v-btn>
												</template>
												<span>{{ lview.actionEdit }}</span>
											</v-tooltip>
											<v-tooltip bottom>
												<template v-slot:activator="{ on, attrs }">
													<v-btn
														v-if="displayAdd"
														class="mr-2"
														color="primary"
														@click="duplicateMdlItem(item)"
														display="true"
														v-bind="attrs"
														v-on="on"
														icon
														text
														small
													>
														<v-icon>
															mdi-content-copy
														</v-icon>
													</v-btn>
												</template>
												<span>{{ lview.actionDuplicate }}</span>
											</v-tooltip>
											<v-tooltip bottom>
												<template v-slot:activator="{ on, attrs }">
													<v-btn
														v-if="displayDelete"
														icon
														text
														@click="deleteMdlItem(item);"
														v-bind="attrs"
														v-on="on"
														color="red"
														small
													>
														<v-icon>
															mdi-delete
														</v-icon>
													</v-btn>
												</template>
												<span>{{ lview.actionDelete }}</span>
											</v-tooltip>
										</template>

										<!-- Mobile -->
										<template v-else>
											<v-menu
											>
												<template v-slot:activator="{ on, attrs }">
													<v-btn
														text
														fab
														ripple
														small
														v-bind="attrs"
														v-on="on"
														style="margin: 0 auto"
													>
														<v-icon color="primary">
															mdi-dots-vertical
														</v-icon>
													</v-btn>
												</template>

												<v-list>
													<v-list-item
														@click="$router.push('/mdl-config/' + item.id)"
													>
														<v-icon style="padding-right:5px">mdi-pencil</v-icon>
														{{ lview.actionEdit }}
													</v-list-item>

													<v-list-item
														@click="duplicateMdlItem(item)"
													>
														<v-icon style="padding-right:5px">mdi-content-copy</v-icon>
														<v-list-item-title>{{ lview.actionDuplicate }}</v-list-item-title>
													</v-list-item>

													<v-list-item style="color:red"
														@click="deleteMdlItem(item)"
													>
														<v-icon style="padding-right:5px; color: red">mdi-delete</v-icon>
														{{ lview.actionDelete }}
													</v-list-item>
												</v-list>
											</v-menu>
										</template>
									</div>
								</div>
							</div>
						</v-list-item>
					</template>

					<div class="permissions-list-see-more" v-if="mdl_groups.length > 6">
						<v-btn
							text
							color="primary"
							v-if="expandMdl"
							@click="expandMdl = false"
						>
							{{ lview.showLess }}
							<v-icon right>
								mdi-menu-up
							</v-icon>
						</v-btn>
						<v-btn
							text
							color="primary"
							v-else
							@click="expandMdl = true"
						>
							{{ lview.showMore }}
							<v-icon right>
								mdi-menu-down
							</v-icon>
						</v-btn>
					</div>
				</v-list>

				<div style="text-align:end">
					<v-btn
						fab
						dark
						small
						style="margin-right: 20px"
						v-if="displayAdd"
						color="orange"
						@click="$router.push('/mdl-config/')"
					>
						<v-icon>mdi-plus</v-icon>
					<!--{{lview.addGroup}}-->
					</v-btn>
				</div>
				<!-- End of MDL roles list -->
			</div>

			<!-- DS ROLES -->
			<div class="groups-table">
				<v-card-actions style='background: #bdbdbd; margin: auto; height: 40px; margin-top: 20px; margin-left:10px; margin-right:10px;'>
					<v-card-text color="black" style = 'font-weight:bold;' text>
						{{lview.dsRole}}
					</v-card-text>
				</v-card-actions>

				<!-- Dialog to delete DS group -->
				<v-dialog
					v-model="dialogDsDelete"
					width="350px"
					persistent
				>
					<v-card >
						<v-card-title>
							<span> {{lview.deleteGroup}} </span>
						</v-card-title>

						<v-card-text>
							<span> {{lview.deleteGroupContent}} </span>
							<br/><br/>
							<strong> {{lview.groupName}}: </strong> <div> {{ editedDsName }} </div>
						</v-card-text>
						
						<v-progress-linear
							v-if="loading"
							indeterminate
							color="primary"
						></v-progress-linear>
						
						<v-card-actions>
							<v-spacer></v-spacer>
							<v-btn @click="closeDsDelete" text :disabled="dialogDsDeleteDisabled">
								{{lview.cancelButton}}
							</v-btn>
							<v-btn @click="deleteDsItemConfirm" color="red" text :disabled="dialogDsDeleteDisabled">
								<strong> {{lview.deleteButton}} </strong>
							</v-btn>
						</v-card-actions>
					</v-card>
				</v-dialog>

				<!-- Dialog to duplicate DS group -->
				<v-dialog
				v-model="dialogDsDuplicate"
				width="350px"
				persistent
				>
					<v-card>
						<v-card-title>
							<span> {{lview.duplicateGroup}}</span>
						</v-card-title>

						<v-card-text>
							<span> {{lview.duplicateGroupContent}} </span>
							<strong> {{ editedDsName }}</strong>?
						</v-card-text>
						
						<v-text-field
							v-model="dialogDsDuplicateGroupName"
							:disabled="dialogDsDuplicateInputDisabled"
							:label="lview.groupName"
							style="margin-left: 7%; margin-right: 5%;"
							:maxlength="140"
							:rules="dialogDsDuplicateNameRules"
							counter
							clearable
						></v-text-field>                

						<v-progress-linear
							v-if="loading"
							indeterminate
							color="primary"
						></v-progress-linear>
						
						<v-card-actions>
							<v-spacer></v-spacer>
							<v-btn @click="closeDsduplicate" text :disabled="dialogDsDuplicateCancelDisabled">
								{{lview.cancelButton}}
							</v-btn>
							<v-btn @click="confirmDsduplicate" color="primary" text :disabled="dialogDsDuplicateSaveDisabled">
								<strong> {{lview.createButton}} </strong>
							</v-btn>
						</v-card-actions>
					</v-card>
				</v-dialog>

				<!-- DS roles list (a list that looks like a table) -->
				<v-list
					:class="(expandDs) ? 'permissions-list expanded' : 'permissions-list'"
				>
					<v-list-item
						class="permissions-header-list-item"
					>
						<div class="permissions-wrapper permissions-header">
							<div class="permissions-wrapper-item permissions-wrapper-item-left">
								{{ lview.headerName }}
							</div>
							<div class="permissions-wrapper-item">
								<span v-if="windowWidth > 450">{{ lview.headerUsers }}</span>
								<span v-else>#</span>
							</div>
							<div class="permissions-wrapper-item">
								<span v-if="windowWidth > 450">{{ lview.headerActions }}</span>
							</div>
						</div>
					</v-list-item>

					<template
						v-for="item in ds_groups"
					>
						<v-list-item
							class="permissions-list-item"
							v-bind:key="item.id"
						>
							<div class="permissions-wrapper">
								<div class="permissions-wrapper-item permissions-wrapper-item-left">
									{{ item.name }}
								</div>
								<div class="permissions-wrapper-item">
									{{ item.users }}
								</div>
								<div class="permissions-wrapper-item">
									<div>
										<!-- Desktop -->
										<template v-if="windowWidth > 450">
											<v-tooltip bottom>
												<template v-slot:activator="{ on, attrs }">
													<v-btn
														v-if="displayEdit"
														class="mr-2"
														color="primary"
														v-bind="attrs"
														v-on="on"
														@click="$router.push('/ds-config/' + item.id)"
														icon
														text
														small
													>
														<v-icon>
															mdi-pencil
														</v-icon>
													</v-btn>
												</template>
												<span>{{ lview.actionEdit }}</span>
											</v-tooltip>
											<v-tooltip bottom>
												<template v-slot:activator="{ on, attrs }">
													<v-btn
														v-if="displayAdd"
														class="mr-2"
														color="primary"
														@click="duplicateDsItem(item)"
														display="true"
														v-bind="attrs"
														v-on="on"
														icon
														text
														small
													>
														<v-icon>
															mdi-content-copy
														</v-icon>
													</v-btn>
												</template>
												<span>{{ lview.actionDuplicate }}</span>
											</v-tooltip>
											<v-tooltip bottom>
												<template v-slot:activator="{ on, attrs }">
													<v-btn
														v-if="displayDelete"
														icon
														text
														@click="deleteDsItem(item);"
														v-bind="attrs"
														v-on="on"
														color="red"
														small
													>
														<v-icon>
															mdi-delete
														</v-icon>
													</v-btn>
												</template>
												<span>{{ lview.actionDelete }}</span>
											</v-tooltip>
										</template>

										<!-- Mobile -->
										<template v-else>
											<v-menu
											>
												<template v-slot:activator="{ on, attrs }">
													<v-btn
														text
														fab
														ripple
														small
														v-bind="attrs"
														v-on="on"
														style="margin: 0 auto"
													>
														<v-icon color="primary">
															mdi-dots-vertical
														</v-icon>
													</v-btn>
												</template>

												<v-list>
													<v-list-item
														@click="$router.push('/ds-config/' + item.id)"
													>
														<v-icon style="padding-right:5px">mdi-pencil</v-icon>
														{{ lview.actionEdit }}
													</v-list-item>

													<v-list-item
														@click="duplicateDsItem(item)"
													>
														<v-icon style="padding-right:5px">mdi-content-copy</v-icon>
														<v-list-item-title>{{ lview.actionDuplicate }}</v-list-item-title>
													</v-list-item>

													<v-list-item style="color:red"
														@click="deleteDsItem(item)"
													>
														<v-icon style="padding-right:5px; color: red">mdi-delete</v-icon>
														{{ lview.actionDelete }}
													</v-list-item>
												</v-list>
											</v-menu>
										</template>
									</div>
								</div>
							</div>
						</v-list-item>
					</template>

					<div class="permissions-list-see-more" v-if="ds_groups.length > 6">
						<v-btn
							text
							color="primary"
							v-if="expandMdl"
							@click="expandMdl = false"
						>
							{{ lview.showLess }}
							<v-icon right>
								mdi-menu-up
							</v-icon>
						</v-btn>
						<v-btn
							text
							color="primary"
							v-else
							@click="expandMdl = true"
						>
							{{ lview.showMore }}
							<v-icon right>
								mdi-menu-down
							</v-icon>
						</v-btn>
					</div>
				</v-list>

				<div style="text-align:end">
					<v-btn
						fab
						dark
						small
						style="margin-right: 20px"
						v-if="displayAdd"
						color="orange"
						@click="$router.push('/ds-config/')"
					>
						<v-icon>mdi-plus</v-icon>
					<!--{{lview.addGroup}}-->
					</v-btn>
				</div>
			</div>
		</v-row>
		</v-container>
	</div>
</template>

<script>
//import axios from 'axios';
const axios = require('axios');
import appConfig from '@/app_config'

  export default {
	data: () => ({
		windowWidth: window.innerWidth,
		expandMdl: false,
		expandDs: false,

		loading: false,
		headers: [
		{ id: 0, text: '', value: 'name' },
		{ id: 1, text: '', value: 'users' },
		{ id: 2, text: '', value: 'actions' },
		],      
		// ds data
		dialogDsDelete: false,
		dialogDsDeleteDisabled: false,
		dialogDsDuplicateGroupName: '',
		dialogDsDuplicate: false,
		dialogDsDuplicateNameRules: [],
		dialogDsDuplicateNameError: "",
		dialogDsDuplicateSaveDisabled: true,
		dialogDsDuplicateCancelDisabled: false,
		dialogDsDuplicateInputDisabled: false,
		ds_groups: [],
		editedDsIndex: -1,
		editedDsId: -1,
		editedDsName: '',
		heightTableDs: null,

		// mdl data
		dialogMdlDuplicate: false,
		dialogMdlDuplicateNameRules: [],
		dialogMdlDuplicateNameError: "",
		dialogMdlDeleteDisabled: false,
		dialogMdlDuplicateGroupName: '',
		dialogMdlDuplicateSaveDisabled: true,
		dialogMdlDuplicateCancelDisabled: false,
		dialogMdlDuplicateInputDisabled: false,
		dialogMdlDelete: false,
		mdl_groups: [],
		editedMdlIndex: -1,
		editedMdlId: -1,
		editedMdlName: '',
		heightTableMdl: null,

		// display icons
		displayEdit: true,
		displayDuplicate: true,
		displayDelete: true,
		displayAdd: true,
	}),

	methods: {
		onResize(){
			this.windowWidth = window.innerWidth;
		},
		// mdl methods  
		checkLenMdlTable() {
			if (this.mdl_groups.length >=7) {
				this.heightTableMdl = '350px';
			} else {
				this.heightTableMdl = null;
			}
		},  
		deleteMdlItem (item) {
			this.dialogMdlDelete = true;
			this.editedMdlIndex = this.mdl_groups.indexOf(item);
			this.editedMdlId = item.id;
			this.editedMdlName = item.name;
		},

		async deleteMdlItemConfirm () {
			let deleteResponse = null;
			this.loading = true;
			this.dialogMdlDeleteDisabled = true;

			if (this.editedMdlId != -1) {
				try {
					deleteResponse = await axios({
						method: "DELETE",
						url: appConfig.getApiUrl(localStorage.getItem("tenantSlug")) + "/api/v1/admin/groups/" + this.editedMdlId,
						headers: {
							'Content-Type': 'application/json',
							'Authorization': 'Bearer ' + localStorage.getItem('jwt')
						},
					});
				} catch (error) {
					if (error.isAxiosError) {
						if (error.response.status == 403) {
							this.closeMdlDelete();  
							this.$emit('display-error', this.lerrors.permissions.youDoNotHavePriviledgesToDoThatOperation.header, this.lerrors.permissions.youDoNotHavePriviledgesToDoThatOperation.string, String(error));
							return;  
						}
					}

					this.closeMdlDelete();
					this.$emit('display-error', this.lerrors.permissions.default_error.header, this.lerrors.permissions.default_error.string, String(error));
					return;
				}

				if (deleteResponse.status == 204) {
					this.mdl_groups.splice(this.editedMdlIndex, 1);
					this.checkLenMdlTable();
				}
			}

			this.closeMdlDelete();
		},

		closeMdlDelete () {
			this.dialogMdlDelete = false;
			this.loading = false;
			this.dialogMdlDeleteDisabled = false;

			this.$nextTick(() => {
			this.editedMdlIndex = -1;
			this.editedMdlId = -1;
			this.editedMdlName = '';
			});
		},

		dialogMdlDuplicateNewGroupName () {
			if(this.dialogMdlDuplicate){
				if(this.dialogMdlDuplicateGroupName == null){
					this.dialogMdlDuplicateSaveDisabled = true;
					this.dialogMdlDuplicateNameError = "Name required"
					return false
				}

				if (this.dialogMdlDuplicateGroupName.length <= 0){
					this.dialogMdlDuplicateSaveDisabled = true;   
					this.dialogMdlDuplicateNameError="Name required"
					return false   
				}

				let idx = this.mdl_groups.findIndex(x => { return x.name.toLowerCase() == this.dialogMdlDuplicateGroupName.toLowerCase(); });
				if (idx != -1) {
					this.dialogMdlDuplicateSaveDisabled = true;
					this.dialogMdlDuplicateNameError = "Group with that name already exists";
					return false;
				}
			}

			this.dialogMdlDuplicateSaveDisabled = false;   
			this.dialogMdlDuplicateNameError=""
			return true
		},

		duplicateMdlItem (item) {
			this.dialogMdlDuplicate = true;
			this.editedMdlIndex = this.mdl_groups.indexOf(item);
			this.editedMdlId = item.id;
			this.editedMdlName = item.name;
			this.dialogMdlDuplicateNewGroupName()
		},

		async confirmMdlduplicate () {
			this.loading = true;
			this.dialogMdlDuplicateSaveDisabled = true;
			this.dialogMdlDuplicateCancelDisabled = true;
			this.dialogMdlDuplicateInputDisabled = true;

			if (this.editedMdlId != -1) {
				let addResponse;
				try {
					addResponse = await axios({
						method: "POST",
						url: appConfig.getApiUrl(localStorage.getItem("tenantSlug")) + "/api/v1/admin/groups/" + this.editedMdlId+"/duplicate/",
						headers: {
							'Content-Type': 'application/json',
							'Authorization': 'Bearer ' + localStorage.getItem('jwt')
						},
						data: {
							name: this.dialogMdlDuplicateGroupName,
						}
					});
				} catch (error) {
					if (error.isAxiosError) {
						if (error.response.status == 403) {
							this.closeMdlduplicate();  
							this.$emit('display-error', this.lerrors.permissions.youDoNotHavePriviledgesToDoThatOperation.header, this.lerrors.permissions.youDoNotHavePriviledgesToDoThatOperation.string, String(error));
							return;  
						}

						if (error.response.data.name != null && error.response.data.name != undefined) {
							this.$emit('display-error', this.lerrors.permissions.name.header, this.lerrors.permissions.name.string, String(error));
							this.closeMdlduplicate();
							return;                    
						}
					}

					// default error
					this.$emit('display-error', this.lerrors.permissions.default_error.header, this.lerrors.permissions.default_error.string, String(error));
					this.closeMdlduplicate();
					return;
				}

				if (addResponse.status == 201) {
					let response = addResponse.data;

					this.mdl_groups.push({
						'id': response.id,
						'name': response.name,
						'users': response.users_count,
						'tooltipEdit': false,
						'tooltipDuplicate': false,
						'tooltipDelete': false
					});

					this.checkLenMdlTable();
				}
			}

			this.closeMdlduplicate();  
		},

		closeMdlduplicate () {
			this.dialogMdlDuplicate = false;
			this.loading = false;
			this.dialogMdlDuplicateCancelDisabled = false;
			this.dialogMdlDuplicateInputDisabled = false;
			this.dialogMdlDuplicateSaveDisabled = true;
			if(this.dialogMdlDuplicateGroupName==''){
				this.dialogMdlDuplicateGroupName=null
			}
			else{
				this.dialogMdlDuplicateGroupName = '';
			}

			this.$nextTick(() => {
			this.editedMdlIndex = -1;
			this.editedMdlId = -1;
			this.editedMdlName = '';
			});
		},

		// ds methods
		checkLenDsTable() {
			if (this.ds_groups.length >=7) {
				this.heightTableDs = '350px';
			} else {
				this.heightTableDs = null;
			}
		},  
		dialogDsDuplicateNewGroupName () {
			if(this.dialogDsDuplicate){
				if(this.dialogDsDuplicateGroupName == null){
					this.dialogDsDuplicateNameError="Name required"
					this.dialogDsDuplicateSaveDisabled = true; 
					return false;
				}

				if (this.dialogDsDuplicateGroupName.length <= 0){
					this.dialogDsDuplicateSaveDisabled = true;   
					this.dialogDsDuplicateNameError="Name required"
					return false   
				}

				let idx = this.ds_groups.findIndex(x => { return x.name.toLowerCase() == this.dialogDsDuplicateGroupName.toLowerCase(); });
				if (idx != -1) {
					this.dialogDsDuplicateSaveDisabled = true;
					this.dialogDsDuplicateNameError = "Group with that name already exists";
					return false;
				}
			}
			this.dialogDsDuplicateSaveDisabled = false;   
			this.dialogDsDuplicateNameError=""
			return true   
		},

		duplicateDsItem (item) {
			this.dialogDsDuplicate = true;
			this.editedDsIndex = this.ds_groups.indexOf(item);
			this.editedDsId = item.id;
			this.editedDsName = item.name;
			this.dialogDsDuplicateNewGroupName()
		},

		async confirmDsduplicate () {
			this.loading = true;
			this.dialogDsDuplicateSaveDisabled = true;
			this.dialogDsDuplicateCancelDisabled = true;
			this.dialogDsDuplicateInputDisabled = true;

			if (this.editedDsId != -1) {
				let addResponse;
				try {
					addResponse = await axios({
						method: "POST",
						url: appConfig.getApiUrl(localStorage.getItem("tenantSlug")) + "/api/v1/admin/groups/" + this.editedDsId+"/duplicate/",
						headers: {
							'Content-Type': 'application/json',
							'Authorization': 'Bearer ' + localStorage.getItem('jwt')
						},
						data: {
							name: this.dialogDsDuplicateGroupName,
						}
					});
				} catch (error) {
					if (error.isAxiosError) {
						if (error.response.status == 403) {
							this.closeDsduplicate();  
							this.$emit('display-error', this.lerrors.permissions.youDoNotHavePriviledgesToDoThatOperation.header, this.lerrors.permissions.youDoNotHavePriviledgesToDoThatOperation.string, String(error));
							return;  
						}

						if (error.response.data.name != null && error.response.data.name != undefined) {
							this.$emit('display-error', this.lerrors.permissions.name.header, this.lerrors.permissions.name.string, String(error));
							this.closeDsduplicate();
							return;                    
						}
						
					}

					// default error
					this.$emit('display-error', this.lerrors.permissions.default_error.header, this.lerrors.permissions.default_error.string, String(error));
					this.closeDsduplicate();
					return;
				}

				if (addResponse.status == 201) {
					let response = addResponse.data;

					this.ds_groups.push({
						'id': response.id,
						'name': response.name,
						'users': response.users_count
					});

					this.checkLenDsTable();
				}
			}
			this.closeDsduplicate();  
		},

		closeDsduplicate () {
			this.dialogDsDuplicate = false;
			this.loading = false;
			this.dialogDsDuplicateCancelDisabled = false;
			this.dialogDsDuplicateInputDisabled = false;
			if(this.dialogDsDuplicateGroupName==''){
				this.dialogDsDuplicateGroupName=null
			}
			else{
				this.dialogDsDuplicateGroupName = '';
			}
			this.dialogDsDuplicateSaveDisabled = true;

			this.$nextTick(() => {
			this.editedDsIndex = -1;
			this.editedDsId = -1;
			this.editedDsName = '';
			});
		},

		deleteDsItem (item) {
			this.dialogDsDelete = true;
			this.editedDsIndex = this.ds_groups.indexOf(item);
			this.editedDsId = item.id;
			this.editedDsName = item.name;
		},

		async deleteDsItemConfirm () {
			let deleteResponse = null;
			this.loading = true;
			this.dialogDsDeleteDisabled = true;

			if (this.editedDsId != -1) {
				try {
					deleteResponse = await axios({
						method: "DELETE",
						url: appConfig.getApiUrl(localStorage.getItem("tenantSlug")) + "/api/v1/admin/groups/" + this.editedDsId,
						headers: {
							'Content-Type': 'application/json',
							'Authorization': 'Bearer ' + localStorage.getItem('jwt')
						},
					});
				} catch (error) {
					if (error.isAxiosError) {
						if (error.response.status == 403) {
							this.closeDsDelete();  
							this.$emit('display-error', this.lerrors.permissions.youDoNotHavePriviledgesToDoThatOperation.header, this.lerrors.permissions.youDoNotHavePriviledgesToDoThatOperation.string, String(error));
							return;  
						}
					}

					this.closeDsDelete();
					this.$emit('display-error', this.lerrors.permissions.default_error.header, this.lerrors.permissions.default_error.string, String(error));
					return;
				}

				if (deleteResponse.status == 204) {
					this.ds_groups.splice(this.editedDsIndex, 1);
					this.checkLenDsTable();
				}
			}

			this.closeDsDelete()
		},

		closeDsDelete () {
			this.dialogDsDelete = false;
			this.loading = false;
			this.dialogDsDeleteDisabled = false;

			this.$nextTick(() => {
			this.editedDsIndex = -1;
			this.editedDsId = -1;
			this.editedDsName = '';
			})
		},

	},
	computed: {
		lview: {
			get: function(){
				return this.$t('views.permissions');
			}
		},
		lerrors: { 
			get: function() { 
				return this.$t('errors'); 
			} 
		},
	},
	mounted: async function () {
		if(!localStorage.getItem('tenantSlug')){
			this.$router.push('/choose-tenant');
			return
		}
		this.$emit('set-state', 'LOADING');

		this.$nextTick(() => {
			window.addEventListener('resize', this.onResize);
		})

		this.$emit('set-title', this.lview.title);
		this.$emit('getGoBackLink', "");
		this.$emit("set-state", "default");

		// setting permissions
		if(localStorage.getItem('permissions') !== null){
			this.permissions = JSON.parse(localStorage.permissions);
		}
		
		if (!this.permissions.includes("admin_view")) {
			this.$emit('set-state', 'ERROR', "You do not have priviledges to this view");
			return
		}

		if (!this.permissions.includes("admin_add")) {
			this.displayAdd = false;
			this.displayDuplicate = false;
		}

		if (!this.permissions.includes("admin_change")) {
			this.displayEdit = false;
		} 

		if (!this.permissions.includes("admin_delete")) {
			this.displayDelete = false;
		}

		this.dialogDsDuplicateNameRules = [
			v => ( this.dialogDsDuplicateNewGroupName(v) ) || this.dialogDsDuplicateNameError
		];

		this.dialogMdlDuplicateNameRules = [
			v => ( this.dialogMdlDuplicateNewGroupName(v) ) || this.dialogMdlDuplicateNameError
		];
		

		this.headers[0].text = this.lview.headerName;
		this.headers[1].text = this.lview.headerUsers;
		this.headers[2].text = this.lview.headerActions;

		let groupResponse = null;
		try {
			groupResponse = await axios({
				method: "GET",
				url: appConfig.getApiUrl(localStorage.getItem("tenantSlug")) + "/api/v1/admin/groups/",
				headers: {
					'Content-Type': 'application/json',
					'Authorization': 'Bearer ' + localStorage.getItem('jwt')
				},
			});
		} catch (error) {
			this.$emit('set-state', 'DEFAULT');
			this.$emit('set-state', 'ERROR', 'Nie można załadować danych.');
			return;
		}
		
		this.$emit('set-state', 'DEFAULT');

		if (groupResponse) {
			let response = groupResponse.data;

			if (response.mdl_groups != null) {
				for (let i=0; i < response.mdl_groups.length; i++) {
					this.mdl_groups.push({
						'id': response.mdl_groups[i].id,
						'name': response.mdl_groups[i].name,
						'users': response.mdl_groups[i].users_count,
						'tooltipEdit': false,
						'tooltipDuplicate': false,
						'tooltipDelete': false
					});
				}
			}
			
			this.checkLenMdlTable();

			if (response.ds_groups != null) {
				for (let i=0; i < response.ds_groups.length; i++) {
					this.ds_groups.push({
						'id': response.ds_groups[i].id,
						'name': response.ds_groups[i].name,
						'users': response.ds_groups[i].users_count
					});
				}
			}            

			this.checkLenDsTable();
		}
	},
  }
</script>

<style scoped>

.groups-table {
	width: 50%;
}

@media screen and (max-width: 1100px) {
	.groups-table {
		width: 100%;
	}
}


.permissions-wrapper {
	width: 100%;
	display: grid;
	grid-gap: 0;
	grid-template-columns: 1fr 103px 100px;
	font-size: 0.875rem;
	text-align: center;
}

.permissions-header-list-item{
	border: 1.5px solid #e0e0e0;
}

.permissions-header {
	font-size: 0.8rem;
	font-weight: bold;
	color: rgba(0,0,0,0.6);
}

.permissions-wrapper-item {
	display: flex;
	flex-direction: column;
	justify-content: center;
}
.permissions-wrapper-item-left {
	text-align: left;
}


@media only screen and (max-width: 450px) {
	.permissions-wrapper {
		grid-template-columns: 1fr 48px 40px;
	}
}


.permissions-list{
	padding-top: 0;
	max-height: 375px;
	overflow: hidden;
	position: relative;
	margin-top: 20px;
	margin-bottom: 10px;
	margin-left: 10px;
	margin-right: 10px;
}
.permissions-list-item {
	border: 1.5px solid #e0e0e0;

}
.permissions-list-see-more {
	position: absolute;
	bottom: 0;
	left: 0;
	width: 100%;
	text-align: center;
	background-image: linear-gradient(to bottom, transparent, white);
}

.permissions-list.expanded {
	max-height: none;
	overflow: unset;
	padding-bottom: 48px;
}
</style>